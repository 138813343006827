
























































































































































































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import moment from 'moment'
import utils from '@/shared/mixins/utils'
import actionPlanDropdownData from '@/assets/data/action-plan-data.json'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import DocumentationLink from '@/shared/components/DocumentationLink.vue'

export default defineComponent({
    name: 'ActionPlanSection',
    props: {
        actionPlan: {
            type: undefined,
        },
        editable: {
            type: Boolean,
        },
        assessmentDetails: {
            type: undefined,
        },
        isMSR: {
            type: Boolean,
        },
    },
    components: {
        DocumentationLink,
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { users } = useState(['users'])
        const actionTypeDropdown = ref([])
        const stateDropdown = ref([])
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const form = ref({
            description: '',
            shortDescription: '',
            actionType: 0,
            documentationUrl: '',
            owner: userId,
            ownerRemark: '',
            startDate: new Date(),
            endDate: new Date(),
        })
        const errors = ref({
            owner: { expire: false },
            actionType: { missingValue: false },
            shortDescription: { missingValue: false },
            endDate: { invalidValue: false },
            documentationUrl: { invalidValue: false },
        })
        const labelInfomationOpTopic = ref(null)
        const labelInfomationOpDescription = ref(null)
        const labelInfomationOpDetailsType = ref(null)
        const labelInfomationOpState = ref(null)
        const labelInfomationOpDocumentationUrl = ref(null)
        const labelInfomationOpOwner = ref(null)
        const labelInfomationOpStartDate = ref(null)
        const labelInfomationOpEndDate = ref(null)
        const labelInfomationOpDaysOpen = ref(null)
        const labelInfomationOpNote = ref(null)
        const labelInfomationOpSubmittedBy = ref(null)

        const employees = ref([])

        const setFormData = () => {
            const actionPlan = props.actionPlan
            form.value = {
                description: actionPlan.description,
                shortDescription: actionPlan.shortDescription,
                actionType: actionPlan.actionType,
                documentationUrl: actionPlan.documentationUrl,
                owner: actionPlan.owner && actionPlan.owner.id,
                ownerRemark: actionPlan.ownerRemark,
                startDate: moment(actionPlan.startDate).toDate(),
                endDate: moment(actionPlan.endDate).toDate(),
            }
        }

        const formatState = (state: string): string => {
            if (!state) return ''
            return t('ACTION_PLAN_STATE_' + state, language).toString()
        }

        const formatActionType = (nr: number): string => {
            return t('ACTION_PLAN_ACTION_TYPE_' + nr, language).toString()
        }

        // set default values ( start date = executionDate, end date = dateNextAssessment )
        const setDefaultDates = () => {
            form.value.startDate = moment(
                props.assessmentDetails && props.assessmentDetails.executionDate
            ).toDate()
            form.value.endDate = moment(
                props.assessmentDetails && props.assessmentDetails.dateNextAssessment
            ).toDate()
        }

        // actionType dropdown
        const setActionTypeDropdownOptions = () => {
            actionTypeDropdown.value = []
            actionPlanDropdownData.ACTION_TYPES.map((actionType) => {
                actionTypeDropdown.value.push({
                    id: actionType.ID,
                    value: t(actionType.VALUE, language.value),
                })
            })
        }

        // state dropdown
        const setStateDropdownOptions = () => {
            stateDropdown.value = []
            actionPlanDropdownData.STATES.map((state) => {
                stateDropdown.value.push({
                    id: state.ID,
                    value: t(state.VALUE, language.value),
                })
            })
        }

        //  validating form
        const validateForm = () => {
            // missingValue checks
            errors.value.actionType.missingValue = utils.validateFields(
                form.value.actionType,
                'number'
            )
            errors.value.endDate.invalidValue = utils.validateFields(form.value.endDate, 'string')
            errors.value.shortDescription.missingValue = utils.validateFields(
                form.value.shortDescription,
                'string'
            )
            // start_date =< end_date
            if (form.value.endDate >= form.value.startDate) {
                errors.value.endDate.invalidValue = false
            } else {
                errors.value.endDate.invalidValue = true
            }
            /* check whether selected owner is expired or not.
            Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
            In this case, it will ask to select an active user
                */
            const owner = employees.value.find((employee) => employee.id === form.value.owner)
            if (owner) errors.value.owner.expire = owner.userExpired
        }

        // clear form fields and validation errors
        const cancelForm = () => {
            form.value = {
                description: '',
                shortDescription: '',
                actionType: 0,
                documentationUrl: '',
                owner: userId,
                ownerRemark: '',
                startDate: new Date(),
                endDate: new Date(),
            }
            errors.value = {
                owner: { expire: false },
                actionType: { missingValue: false },
                shortDescription: { missingValue: false },
                endDate: { invalidValue: false },
                documentationUrl: { invalidValue: false },
            }
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        // employees list should only contain the users with role as employee
        const setOwnerDropdown = () => {
            employees.value = []
            users.value.map((user) => {
                // get user loggedin organisation (current org)
                const currentOrganisation = user.organisations.filter(
                    (org) => org.id === Number(organisationId)
                )
                // get users who are available in current organisation with 'Employee' role
                const filteredUsers =
                    currentOrganisation[0] &&
                    !!currentOrganisation[0].roles.find(
                        (role) => role.name === UserRole.EMPLOYEE
                    ) &&
                    user.userExpired !== true
                if (filteredUsers) {
                    employees.value.push(user)
                }
            })
        }

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return !props.actionPlan || props.editable
        })

        onMounted(() => {
            if (props.actionPlan && props.actionPlan.id) {
                setFormData()
            }
            setActionTypeDropdownOptions()
            setStateDropdownOptions()
            setOwnerDropdown()
        })

        //  watch for actionPlan changes
        watch(
            () => Object.assign({}, props.actionPlan),
            () => {
                if (props.actionPlan) {
                    setFormData()
                }
            }
        )

        //  watch for actionPlan changes
        watch(
            () => Object.assign({}, props.assessmentDetails),
            () => {
                if (
                    ((props.assessmentDetails && props.assessmentDetails.executionDate) ||
                        (props.assessmentDetails && props.assessmentDetails.dateNextAssessment)) &&
                    !props.actionPlan
                ) {
                    setDefaultDates()
                }
            }
        )

        return {
            actionTypeDropdown,
            errors,
            form,
            formatState,
            formatActionType,
            language,
            moment,
            stateDropdown,
            t,
            users,
            utils,
            validateForm,
            cancelForm,
            labelInfomationOpTopic,
            labelInfomationOpDescription,
            labelInfomationOpDetailsType,
            labelInfomationOpState,
            labelInfomationOpDocumentationUrl,
            labelInfomationOpOwner,
            labelInfomationOpStartDate,
            labelInfomationOpEndDate,
            labelInfomationOpDaysOpen,
            labelInfomationOpNote,
            toggleInformation,
            employees,
            labelInfomationOpSubmittedBy,
            isEditableMode,
        }
    },
})
