


































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import { useI18n } from 'vue-i18n-composable'
export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        isMSR: {
            type: Boolean,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const keyControlName: Ref<TranslateResult> = ref('')

        const labelInfomationOpDescription = ref(null)
        const labelInfomationOpKeyControl = ref(null)
        const labelInfomationOpExecutionGuide = ref(null)
        const labelInfomationOpNote = ref(null)
        const labelInfomationOpImplementationGuide = ref(null)
        const labelInfomationOpAdditionalInfomation = ref(null)
        // convert id values to name values
        const setFormattedDetails = () => {
            keyControlName.value = props.entityDetails.keyControl
                ? t('KEY_CONTROL_OPTION_1', language.value)
                : t('KEY_CONTROL_OPTION_2', language.value)
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        onMounted(() => {
            setFormattedDetails()
        })
        return {
            t,
            language,
            utils,
            keyControlName,
            labelInfomationOpDescription,
            labelInfomationOpKeyControl,
            labelInfomationOpExecutionGuide,
            labelInfomationOpNote,
            labelInfomationOpImplementationGuide,
            labelInfomationOpAdditionalInfomation,
            toggleInformation,
        }
    },
})
