




















































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import moment from 'moment'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import { hasUserRole } from '@/shared/utils/userRoleCheck'

export default defineComponent({
    name: 'ActionPlanApproval',
    props: {
        approvalContent: {
            type: Object,
        },
        disable: {
            type: Boolean,
        },
        actionPlan: {
            type: Object,
        },
        isMSR: {
            type: Boolean,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { users } = useState(['users'])
        // get logged in user info
        const loggedInUser: { user: { id; organisations } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const isUserValidator = hasUserRole(loggedInUser.user.organisations, UserRole.VALIDATOR)
        const actionPlan = props.actionPlan
        // actionplan approve form
        const actionPlanApprovalForm = ref({
            // the reviewer is the logged-in user by default (but cannot be the submitted user, then it's empty)
            APPROVED_BY:
                actionPlan && actionPlan.submittedBy && actionPlan.submittedBy.id == userId
                    ? 0
                    : userId,
            APPROVED_ON: new Date(),
            APPROVAL_REMARK: '',
        })
        const errors = ref({
            APPROVED_BY: { missingValue: false },
            APPROVED_ON: { missingValue: false },
        })
        const labelInfomationOpApprovedBy = ref(null)
        const labelInfomationOpApprovedOn = ref(null)
        const validators = ref([])
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })

        //  validating form
        const validateForm = () => {
            errors.value.APPROVED_BY.missingValue = utils.validateFields(
                actionPlanApprovalForm.value.APPROVED_BY,
                'number'
            )
            errors.value.APPROVED_ON.missingValue = utils.validateFields(
                actionPlanApprovalForm.value.APPROVED_ON,
                'string'
            )
        }

        const getValidators = () => {
            validators.value = []
            // exclude assessment submitted user from users list
            const filterdUsers = users.value.filter(
                (user) => user.id !== (actionPlan.submittedBy && actionPlan.submittedBy.id)
            )
            filterdUsers.map((user) => {
                // get user loggedin organisation (current org)
                const currentOrganisation = user.organisations.filter(
                    (org) => org.id === Number(organisationId)
                )
                // get users who are available in current organisation with 'Employee' role
                const filterdValidator =
                    currentOrganisation[0] &&
                    !!currentOrganisation[0].roles.find((role) => role.name === UserRole.VALIDATOR)
                if (filterdValidator) {
                    validators.value.push(user)
                }
            })
        }

        // clear fields and errors
        const cancelForm = () => {
            actionPlanApprovalForm.value = {
                APPROVED_BY:
                    actionPlan && actionPlan.submittedBy && actionPlan.submittedBy.id == userId
                        ? 0
                        : userId,
                APPROVED_ON: new Date(),
                APPROVAL_REMARK: '',
            }
            errors.value = {
                APPROVED_BY: { missingValue: false },
                APPROVED_ON: { missingValue: false },
            }
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)
            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return !props.approvalContent
        })
        //  watch for actionplan content changes
        watch(
            () => props.actionPlan,
            () => {
                if (props.actionPlan) {
                    getValidators()
                    actionPlanApprovalForm.value.APPROVED_BY =
                        props.actionPlan.submittedBy && props.actionPlan.submittedBy.id == userId
                            ? 0
                            : userId
                }
            },
            { immediate: true }
        )

        return {
            t,
            language,
            actionPlanApprovalForm,
            validators,
            moment,
            validateForm,
            errors,
            cancelForm,
            labelInfomationOpApprovedBy,
            labelInfomationOpApprovedOn,
            toggleInformation,
            utils,
            isEditableMode,
            isUserValidator,
            userId,
        }
    },
})
