import { render, staticRenderFns } from "./ActionPlanSection.vue?vue&type=template&id=37b9c3bd&scoped=true&"
import script from "./ActionPlanSection.vue?vue&type=script&lang=ts&"
export * from "./ActionPlanSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b9c3bd",
  null
  
)

export default component.exports