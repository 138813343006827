var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"control-section"},[_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-3 label-section"},[_vm._v(" "+_vm._s(_vm.t('CONTROL_DETAILS_DESCRIPTION', _vm.language))+" "),_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.toggleInformation($event, _vm.labelInfomationOpDescription)}}})]),_c('div',{staticClass:"p-col-9 data-section"},[(_vm.entityDetails.description)?_c('read-more',{staticClass:"show-more",attrs:{"more-str":_vm.t('SHOW_MORE', _vm.language),"less-str":_vm.t('SHOW_LESS', _vm.language),"maxChars":350,"text":_vm.entityDetails.description ? _vm.utils.marked(_vm.entityDetails.description) : '',"link":"#"}}):_vm._e()],1)]),(!_vm.isMSR)?_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-3 label-section"},[_vm._v(" "+_vm._s(_vm.t('CONTROL_DETAILS_KEY_CONTROL', _vm.language))+" "),_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.toggleInformation($event, _vm.labelInfomationOpKeyControl)}}})]),_c('div',{staticClass:"p-col-9 data-section"},[_vm._v(" "+_vm._s(_vm.keyControlName)+" ")])]):_vm._e(),_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-3 label-section"},[_vm._v(" "+_vm._s(_vm.t('CONTROL_DETAILS_IMPLEMENTATION_GUIDE', _vm.language))+" "),_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.toggleInformation($event, _vm.labelInfomationOpImplementationGuide)}}})]),_c('div',{staticClass:"p-col-9 data-section"},[(_vm.entityDetails.implementationGuide)?_c('read-more',{staticClass:"show-more",attrs:{"more-str":_vm.t('SHOW_MORE', _vm.language),"less-str":_vm.t('SHOW_LESS', _vm.language),"maxChars":350,"text":_vm.entityDetails.implementationGuide ?
                    _vm.utils.marked(_vm.entityDetails.implementationGuide) : '',"link":"#"}}):_vm._e()],1)]),_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-3 label-section"},[_vm._v(" "+_vm._s(_vm.t('CONTROL_DETAILS_ADDITIONAL_INFORMATION', _vm.language))+" "),_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.toggleInformation($event, _vm.labelInfomationOpAdditionalInfomation)}}})]),_c('div',{staticClass:"p-col-9 data-section"},[(_vm.entityDetails.implementationGuideAdditionalInformation)?_c('read-more',{staticClass:"show-more",attrs:{"more-str":_vm.t('SHOW_MORE', _vm.language),"less-str":_vm.t('SHOW_LESS', _vm.language),"maxChars":350,"text":_vm.entityDetails.implementationGuideAdditionalInformation ?
                    _vm.utils.marked(_vm.entityDetails.implementationGuideAdditionalInformation) : '',"link":"#"}}):_vm._e()],1)]),_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-3 label-section"},[_vm._v(" "+_vm._s(_vm.t('CONTROL_DETAILS_EXECUTION_GUIDE', _vm.language))+" "),_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.toggleInformation($event, _vm.labelInfomationOpExecutionGuide)}}})]),_c('div',{staticClass:"p-col-9 data-section"},[(_vm.entityDetails.executionGuide)?_c('read-more',{staticClass:"show-more",attrs:{"more-str":_vm.t('SHOW_MORE', _vm.language),"less-str":_vm.t('SHOW_LESS', _vm.language),"maxChars":350,"text":_vm.entityDetails.executionGuide ? _vm.utils.marked(_vm.entityDetails.executionGuide) : '',"link":"#"}}):_vm._e()],1)]),_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-3 label-section"},[_vm._v(" "+_vm._s(_vm.t('CONTROL_DETAILS_NOTE', _vm.language))+" "),_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.toggleInformation($event, _vm.labelInfomationOpNote)}}})]),_c('div',{staticClass:"p-col-9 data-section"},[(!_vm.isMSR && _vm.entityDetails.controlExplanation)?_c('read-more',{staticClass:"show-more",attrs:{"more-str":_vm.t('SHOW_MORE', _vm.language),"less-str":_vm.t('SHOW_LESS', _vm.language),"maxChars":350,"text":_vm.entityDetails.controlExplanation ?
                    _vm.utils.marked(_vm.entityDetails.controlExplanation) : '',"link":"#"}}):_vm._e(),(_vm.isMSR && _vm.entityDetails.msrExplanation)?_c('read-more',{staticClass:"show-more",attrs:{"more-str":_vm.t('SHOW_MORE', _vm.language),"less-str":_vm.t('SHOW_LESS', _vm.language),"maxChars":350,"text":_vm.entityDetails.msrExplanation ? _vm.utils.marked(_vm.entityDetails.msrExplanation) : '',"link":"#"}}):_vm._e()],1)]),_c('OverlayPanel',{ref:"labelInfomationOpDescription",staticClass:"information-overlay-panel",attrs:{"appendTo":"body","showCloseIcon":true,"id":"label_overlay_panel"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                ("" + (_vm.isMSR
                        ? _vm.t('MSR_LABEL_DESCRIPTION_INFO', _vm.language)
                        : _vm.t('CONTROL_LABEL_DESCRIPTION_INFO', _vm.language)))
            )}})]),_c('OverlayPanel',{ref:"labelInfomationOpKeyControl",staticClass:"information-overlay-panel",attrs:{"appendTo":"body","showCloseIcon":true,"id":"label_overlay_panel"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.t('CONTROL_LABEL_KEY_CONTROL_INFO', _vm.language))}})]),_c('OverlayPanel',{ref:"labelInfomationOpImplementationGuide",staticClass:"information-overlay-panel",attrs:{"appendTo":"body","showCloseIcon":true,"id":"label_overlay_panel"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                ("" + (_vm.isMSR
                        ? _vm.t('MSR_DETAILS_LABEL_IMPLEMENTATION_GUIDE_INFO', _vm.language)
                        : _vm.t('CONTROL_DETAILS_LABEL_IMPLEMENTATION_GUIDE_INFO', _vm.language)))
            )}})]),_c('OverlayPanel',{ref:"labelInfomationOpAdditionalInfomation",staticClass:"information-overlay-panel",attrs:{"appendTo":"body","showCloseIcon":true,"id":"label_overlay_panel"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                ("" + (_vm.isMSR
                        ? _vm.t('MSR_DETAILS_LABEL_ADDITIONAL_INFOMATION_INFO', _vm.language)
                        : _vm.t('CONTROL_DETAILS_LABEL_ADDITIONAL_INFOMATION_INFO', _vm.language)))
            )}})]),_c('OverlayPanel',{ref:"labelInfomationOpExecutionGuide",staticClass:"information-overlay-panel",attrs:{"appendTo":"body","showCloseIcon":true,"id":"label_overlay_panel"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                ("" + (_vm.isMSR
                        ? _vm.t('MSR_DETAILS_LABEL_EXECUTION_GUIDE_INFO', _vm.language)
                        : _vm.t('CONTROL_DETAILS_LABEL_EXECUTION_GUIDE_INFO', _vm.language)))
            )}})]),_c('OverlayPanel',{ref:"labelInfomationOpNote",staticClass:"information-overlay-panel",attrs:{"appendTo":"body","showCloseIcon":true,"id":"label_overlay_panel"}},[_c('div',{domProps:{"innerHTML":_vm._s(
                ("" + (_vm.isMSR
                        ? _vm.t('MSR_DETAILS_LABEL_NOTE_INFO', _vm.language)
                        : _vm.t('CONTROL_DETAILS_LABEL_NOTE_INFO', _vm.language)))
            )}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }